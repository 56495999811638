import React from 'react';
import { File } from '@model/generated/index';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, PageProps } from 'gatsby';
import { Layout } from '@components/global/Layout';
import { navigationItemsProjects } from '@model/projekte/navigationItemsProjects';
import { Meta } from '@components/global/Meta';

type DataProps = {
  image1: File;
  image2: File;
  image3: File;
  image4: File;
};

type Props = PageProps<DataProps>;

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "projekte/robert_sochacki/image1.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image2: file(relativePath: { eq: "projekte/robert_sochacki/image2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image3: file(relativePath: { eq: "projekte/robert_sochacki/image3.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image4: file(relativePath: { eq: "projekte/robert_sochacki/image4.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
  }
`;

const Page: React.FC<Props> = (props) => {
  const { data } = props;
  return (
    <Layout title="Projekte" navigationItems={navigationItemsProjects}>
      <div className="flex">
        <div className="flex-auto">
          <GatsbyImage
            objectFit="cover"
            image={data.image1.childImageSharp.gatsbyImageData}
            alt="Projekt Robert Sochacki"
          />
          <div className="flex-1 p-32 mb-16">
            <h3>Robert Sochacki</h3>
            <p>
              <b>Robert Sochacki</b>, Phd - geboren in Gdansk, PL (1971), wo er
              derzeit lebt. Er ist akademischer Lehrer an der Akademie der
              Bildenden Künste, Abt. Malerei, Studio Kunst im öffentlichen Raum
              in Danzig und an der Abteilung Bühnenbild der Eugeniusz Geppert
              Akademie für Kunst und Design in Breslau. Seit fast 25 Jahren ist
              er ein aktiver Künstler, der in verschiedenen Bereichen arbeitet,
              vom Theater Bühnenbildner, über VJ&apos;ing, Performance und
              Außenprojektionen bis hin zu interaktiven Lichtinstallationen.
              Während seines Promotionsstudiums begann er seine theoretische
              Forschung über das Zusammenspiel zwischen öffentlicher Kunst,
              Licht und dem Betrachter. Der Faktor, der es dem Künstler erlaubt,
              mit seinem Publikum in Dialog zu treten, interessiert ihn am
              meisten.
            </p>
            <div className="flex flex-col my-32">
              <GatsbyImage
                className="my-8 md:my-0 md:-mb-40 lg:-mb-92 self-end z-10 w-full md:w-1/2"
                objectFit="cover"
                image={data.image2.childImageSharp.gatsbyImageData}
                alt="Projekt Robert Sochacki"
              />
              <GatsbyImage
                className="my-8 md:my-0 max-w-672 w-full"
                objectFit="contain"
                image={data.image3.childImageSharp.gatsbyImageData}
                alt="Projekt Robert Sochacki"
              />
              <GatsbyImage
                className="my-8 md:my-0 md:-mt-40 lg:-mt-92 self-end w-full md:w-1/2"
                objectFit="cover"
                image={data.image4.childImageSharp.gatsbyImageData}
                alt="Projekt Robert Sochacki"
              />
            </div>
            <p>
              Dank seiner Erfahrung wurde er nicht nur eingeladen, seine
              künstlerische Arbeit auf vielen internationalen Festivals zu
              zeigen (2018: LUNA, Leeuwarden, Niederlande , Lichtrouten
              Lüdenscheid, Deutschland, LUMINA Festival of Light, Cascais,
              Portugal, INTERFERENCE Tunis, Tunesien), sondern auch mit
              Studenten bei verschiedenen Workshops zur neuen Medienkunst zu
              arbeiten (z.B. beim Lichtcampus innovation through light Wismar
              2019, Cultural Heritage studio in Tunis während der Interference
              2018, SEE Djerba 2017, Wroclaw-Gdansk academic exchange 2016).
            </p>
            <p className="pb-16">
              Im Jahr 2018 leitete er die internationale Konferenz und den
              Workshop für Studenten im öffentlichen Raum &quot;Licht als
              kreatives Werkzeug&quot; in Danzig.
            </p>

            <div className="pt-32 -mb-16">
              <p>
                <b>Wunderkammer 360 - Robert Sochaki</b>
              </p>
              <iframe
                title="Wunderkammer Robert Sochaki"
                className="pb-8 h-256 lg:h-512"
                allow="fullscreen"
                frameBorder="0"
                src="https://www.youtube.com/embed/Y18gs70lm_w"
                width="100%"
              />
              <iframe
                title="Wunderkammer 360grad Robert Sochaki"
                className="pb-32 h-256 lg:h-512"
                allow="fullscreen"
                frameBorder="0"
                src="https://www.youtube.com/embed/ZGBJeNMipqA"
                width="100%"
              />
            </div>
            <b>
              <a
                href="http://robertsochacki.pl"
                target="_blank"
                rel="noopener noreferrer"
              >
                Zur Webseite des Künstlers
              </a>
            </b>
          </div>
        </div>
        <h1 style={{ writingMode: 'vertical-rl' }}>Wunderkammer 360°</h1>
      </div>
    </Layout>
  );
};

export const Head: React.FC<Props> = (props) => {
  return (
    <Meta
      description="Robert Sochacki, Phd - geboren in Gdansk, PL (1971), wo er
      derzeit lebt. Er ist akademischer Lehrer an der Akademie der
      Bildenden Künste, Abt. Malerei, Studio Kunst im öffentlichen Raum
      in Danzig und an der Abteilung Bühnenbild der Eugeniusz Geppert
      Akademie für Kunst und Design in Breslau. Seit fast 25 Jahren ist
      er ein aktiver Künstler, der in verschiedenen Bereichen arbeitet,
      vom Theater Bühnenbildner, über VJ'ing, Performance und
      Außenprojektionen bis hin zu interaktiven Lichtinstallationen.
      Während seines Promotionsstudiums begann er seine theoretische
      Forschung über das Zusammenspiel zwischen öffentlicher Kunst,
      Licht und dem Betrachter. Der Faktor, der es dem Künstler erlaubt,
      mit seinem Publikum in Dialog zu treten, interessiert ihn am
      meisten."
      publicURL={props.data.image3.publicURL}
      title="Wunderkammer 360°"
    />
  );
};

export default Page;
